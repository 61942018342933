import * as React from 'react';
import Layout from '../components/Layout';

//CSS
import '../styles/index.scss';
import '../styles/grids.scss';
import '../styles/particles.scss';
import '../styles/buttons.scss';
import '../styles/elements.scss';
import SEO from '../components/seo';

// markup
const ContactPage = () => {
  const props = {
    seoTitle: 'Managed Services',
    seoDescription:
      'Accelerate your business with Strategix Technology Solutions.',
  };

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        {/* <section>
          <div className="hero" id="contact">
            <div className="flex-col-c">
              <h2>Privacy</h2>
            </div>
          </div>
        </section> */}

        {/* Contact */}
        <section className="half privacy" style={{}}>
          <h2>Privacy Policy</h2>
          <p>
            <strong>Last updated: 2021-07-01</strong>
          </p>
          <p>
            Our Company operates https://www.strategix.digital/. This page
            informs you of our policies regarding the collection, use and
            disclosure of Personal Information we receive from users of the
            Site.
          </p>
          <p>
            We use your Personal Information only for providing and improving
            the Site. By using the Site, you agree to the collection and use of
            information in accordance with this policy.
          </p>
          <h2>Strategix Data Privacy Statement</h2>
          <p>
            Strategix Group and its affiliates (collectively “Strategix” “us”
            and “we”) understand the importance of protecting your personal
            data.
          </p>
          <p>
            This Privacy Statement describes how Strategix collects and uses
            your personal data, who we share it with, and your choices and
            rights in relation to your personal data.
          </p>{' '}
          <p>
            It applies to personal data that we collect from you during your
            interactions with us, whether online, including through our websites
            (including mobile sites) and social media sites (“Sites”), mobile
            applications (“Apps”) (collectively “Online Services”) that link to
            this Privacy Statement, in writing or orally, or personal data that
            we may collect offline or receive from third parties.
          </p>
          <h2>Personal data we collect</h2>
          <p>
            Personal data is any information that identifies you as an
            individual or relates to you as an identifiable individual.
          </p>
          <p>
            Depending on how you interact with us, personal data we collect may
            include:
          </p>
          <ul>
            <li>your name;</li>
            <li>email address;</li>
            <li>postal address;</li>
            <li>telephone number;</li>
            <li>
              log-in and account information for authentications purposes and
              account access;
            </li>
            <li>your gender; and</li>
            <li>social media account information</li>
          </ul>
          <p>
            We may also collect other information that does not personally
            identify you. Such other information includes browser and device
            information, website and application usage data, IP addresses,
            demographic information such as marketing preferences, geographic
            location, primary language, and information collected through
            cookies and other technologies or information that has been
            anonymised or aggregated. If we link this information with your
            personal data, we will treat that linked information as personal
            data.
          </p>
          <p>
            You can choose not to provide personal data to us when requested.
            However, if this is necessary to provide you with our solutions and
            services, access to our Online Services, or to perform
            administrative functions, we may be unable to do these things.
          </p>
          <h2>Sensitive personal data</h2>
          <p>
            We do not collect sensitive personal data about you, e.g.
            information relating to your health, religion, political beliefs,
            race or sexual orientation and ask that you do not send or provide
            this information to us.
          </p>
          <h2>How we collect your personal data</h2>
          <p>
            We may collect your personal data from you in a variety of ways when
            you interact with us, including:
          </p>
          <p>
            • when you access our Online Services, or interact with us in any
            other way, or use our solutions and services.
          </p>
          <p>
            We collect personal data when you place orders for our solutions and
            services, you create an account with us, we process your orders and
            payment transactions, perform administrative and business functions,
            market our solutions and services to you and when you register for
            our events, workshops and seminars or subscribe to our mailing lists
            and newsletters.
          </p>
          <p>• when you communicate with us.</p>
          <p>
            We collect personal data when we respond to your inquiries and
            requests, obtain feedback from you about our solutions and services
            or you apply for employment with us.
          </p>
          <p>• from third party sources.</p>
          <p>
            We collect personal data from third parties, including public
            databases, social media sites, business partners with whom we offer
            co-branded services or engage in joint marketing activities and
            third parties that provide list enhancement or similar services.
            When you use our Online Services, we may, and third parties we
            engage may automatically collect data, including personal data
            through use of cookies and similar technologies. For more
            information, see the “Cookies and Similar Technologies” section
            below.
          </p>
          <h2>Legal basis for processing your personal data</h2>
          <p>
            When we process your personal data in connection with the purposes
            set out in this Privacy Statement, we may rely on one or more of the
            following legal bases, depending on the purpose for which the
            processing activity is undertaken and the nature of our relationship
            with you:
          </p>
          <p>
            our legitimate interests (or those of a third party with whom we
            share your personal data) for the purpose of managing, operating or
            promoting our business, including direct marketing, and
            intra-Strategix group transfers of personal data for business and
            administrative purposes, except where such interests are overridden
            by your interests or fundamental rights or freedoms which require
            protection of personal data.
          </p>
          <ul>
            <li>
              where this is necessary to comply with a legal obligation on us.
            </li>
            <li>to protect the vital interests of any individual.</li>
            <li>where you have consented.</li>
          </ul>
          <h2>Use of your personal data</h2>
          <ul>
            <li>
              enable you to effectively use and to improve our solution and
              services. For example, to:
            </li>
            <li>
              perform administrative and business functions and internal
              reporting.
            </li>
            <li>send administrative information to you.</li>
            <li>
              obtain feedback from you about our services and solutions
              including through client satisfaction surveys, in which event, we
              will only use your personal data for the sole purpose of sending
              you a survey (through our third party email delivery provider).
            </li>
            <li>respond to your inquiries and fulfill requests by you.</li>
            <li>
              assess the performance of our Online Services and to improve their
              operation.
            </li>
            <li>
              inform you about and provide you with our services and solutions.
            </li>
            <li>update our records and keep contact details up to date.</li>
            We engage in these activities to manage our contractual relationship
            with you, to comply with our legal obligations, or for our
            legitimate business interests. provide you with marketing materials
            and to personalise your experience. For example, to:
            <li>send marketing communications to you.</li>
            <li>
              provide you with a more personalised experience when you interact
              with us.
            </li>
            <li>
              enable you to subscribe to our newsletters and mailing lists.
            </li>
            <li>
              enable you to register for Strategix events, workshops and
              seminars.
            </li>
            <li>
              We engage in these activities with your consent or for our
              legitimate business interests.
            </li>
            achieve our business purposes and analyse information. For example,
            to:
            <li>
              establish, manage, and maintain our business relationship with
              you.
            </li>
            <li>compile usage statistics for our Online Services.</li>
            <li>recruit staff.</li>
            <li>
              process and respond to privacy requests, questions, concerns and
              complaints.
            </li>
            <li>fulfil legal and contractual obligations.</li>
            <p>
              We engage in these activities to manage our contractual
              relationship with you, to comply with a legal obligation and for
              our legitimate business interests.
            </p>
          </ul>
          <h2>Sharing your personal data</h2>
          <ul>
            <li>
              We may share your personal data for the purposes set out in this
              Privacy Statement (as applicable):
            </li>
            <li>
              with our affiliates and subsidiaries for the purposes set out in
              this Privacy Statement.
            </li>
            <li>
              with business partners with whom we offer co-branded services or
              engage in joint marketing activities.
            </li>
            <li>
              with service providers to provide operational services or
              facilitate transactions on our behalf, including but not limited
              to processing of orders, assisting with sales-related activities
              or post-sales support, client support, email delivery, data
              analytics and auditing.
            </li>
            <li>where you consent to the sharing of your personal data.</li>
            <li>
              in connection with, any joint venture, merger, sale of company
              assets, consolidation or restructuring, financing, or acquisition
              of all or a portion of our business by or to another company.
            </li>
            <li>for other legal reasons.</li>
            <li>
              We may share your personal data in response to a request for
              information by a competent authority in accordance with, or
              required by any applicable law, regulation or legal process;
            </li>
            <li>
              where necessary to comply with judicial proceedings, court orders
              or government orders; or
            </li>
            <li>
              to protect the rights, property or safety of Strategix, its
              business partners, you, or others, or as otherwise required by
              applicable law.
            </li>
            <li>
              Any third parties with whom we share personal data are
              contractually required to implement appropriate data protection
              and security measures to protect personal data and are not
              permitted to use personal data for any purpose other than the
              purpose for which they are provided with or given access to
              personal data.
            </li>
          </ul>
          <h2>Security of your personal data</h2>
          <p>
            Strategix is committed to protecting your personal data from
            accidental or unlawful destruction, loss, alteration, unauthorised
            access or disclosure by using a combination of physical,
            administrative and technical safeguards and contractually requiring
            that third parties to whom we disclose your personal data do the
            same.
          </p>
          <h2>Cookies and Similar Technologies</h2>
          <p>
            When you access our Online Services, we use cookies (small text
            files containing a unique ID number which are placed on your PC or
            device) and similar technologies including scripts, embedded web
            links, web beacons, Local Shared Objects (flash cookies) and Local
            Storage (HTML 5). We use cookies to assist us with activities such
            as:
          </p>
          <ul>
            enabling you to sign in to our Online Services;
            <li>authenticating you;</li>
            <li>keeping track of information you have provided to us;</li>
            <li>improving your browsing experience;</li>
            <li>customising our interactions with you;</li>
            <li>storing and managing your preferences and settings;</li>
            <li>compiling statistical data;</li>
            <li>
              analysing the performance and usability of our Online Services;
            </li>
            <li>measuring traffic patterns for our Online Services; and</li>
            <li>
              determining which areas of our Online Services have been visited.
            </li>
          </ul>
          <p>
            These technologies collect information that your browser sends to
            our Online Services including your browser type, information about
            your IP address (a unique identifier assigned to your computer or
            device which allows your PC or device to communicate over the
            Internet), together with the date, time and duration of your visit,
            the pages you view and the links you click.
          </p>
          <p>
            Our Online Services may also contain web beacons or similar
            technologies from third party analytics providers, through which
            they collect information about your activities across our Online
            Services to help us compile aggregated statistics.
          </p>
          <p>
            For more information on how we use cookies and other technologies
            please see our cookies statement which you can find here.
          </p>
          <h2>Links to third party websites and applications</h2>
          <p>
            {' '}
            Our Online Services may contain links to third party websites and
            applications. We are not responsible for and make no representations
            or warranties in relation to the privacy practices or content of any
            third party websites and applications. Your use of such sites and
            applications is subject to the applicable third party privacy
            statement and is at your own risk.
          </p>
          <h2>Direct marketing</h2>
          <p>
            We may send you direct marketing communications about our solutions
            and services. You can choose whether you wish to receive marketing
            communications from Strategix by email, SMS, post and phone. You may
            opt out of receiving marketing materials from us at any time and
            manage your communication preferences by following the unsubscribe
            instructions included in each marketing email or SMS text message
            from us; sending an email to info@strategix.co.za
          </p>
          <p>
            Include your details and a description of the marketing material you
            no longer wish to receive from us. We will comply with your request
            as soon as is reasonably practicable.
          </p>
          <p>
            If you opt out of receiving marketing related communications from
            us, we may still send you administrative messages as part of your
            ongoing use of our solutions and services, which you will be unable
            to opt out of.
          </p>
          <p>
            We do not provide your personal data to unaffiliated third parties
            for direct marketing purposes or sell, rent, distribute or otherwise
            make personal data commercially available to any third party.
          </p>
          <h2>Updates to this Privacy Statement</h2>
          <p>
            We may update this Privacy Statement at any time. If we do, we will
            update the “last modified” section at the bottom of this Privacy
            Statement.
          </p>
          <p>
            We encourage you to regularly review this Privacy Statement to stay
            informed about our privacy practices.
          </p>
          <h2>How to contact us</h2>
          <p>
            If you have any questions about how your personal data is handled by
            Strategix, you have a privacy concern or you wish to make a request
            or a complaint relating to your personal data, please contact us.
          </p>
          <p>You can reach us at info@strategix.co.za</p>
        </section>
      </main>
    </Layout>
  );
};

export default ContactPage;
